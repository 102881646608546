/**
 * Imports
 * =============================================================================
 */
import { getElBoundingBox } from "./../../utils/dom";

// =============================================================================
// Definition
// =============================================================================
class Content {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.container = this.el.querySelector(".c-collapse__container");
		this.height = this.getHeight();
	}

	// Helpers
	// -------------------------------------------------------------------------
	getHeight() {
		return getElBoundingBox(this.container, ["height"]);
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleResize() {
		this.update();
	}

	open() {
		this.update();
	}

	close() {
		this.el.style.height = 0;
	}

	update() {
		this.height = this.getHeight();
		this.el.style.height = `${this.height}px`;
	}
}

export default Content;
