/**
 * Imports
 * =============================================================================
 */
import { getElBoundingBox } from "./../../utils/dom";

// =============================================================================
// Definition
// =============================================================================
class ExplanationIteù {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;
	}

	// Helpers
	// -------------------------------------------------------------------------
	getTop() {
		return getElBoundingBox(this.el, ["top"]);
	}

	getHeight() {
		return getElBoundingBox(this.el, ["height"]);
	}
}

export default ExplanationIteù;
