// =============================================================================
// Imports
// =============================================================================
import { init as initEl } from "./utils/dom";
import {
	Popup,
	Collapse,
	Header,
	Map,
	Explanation,
	AnimatedGlobe,
	Video,
	Filter,
} from "./modules";

// =============================================================================
// Definition
// =============================================================================
// Initialise all JavaScript modules
const init = () => {
	// initEl(selector, Module);
	initEl(".js-collapse", Collapse);
	initEl(".js-pop-up", Popup);
	initEl(".js-header", Header);
	initEl(".js-map", Map);
	initEl(".js-video", Video);
	initEl(".js-explanation", Explanation);
	initEl(".js-animated-globe", AnimatedGlobe);
	initEl(".js-filter", Filter);
};

/**
 * Check if the document is ready to initialise the JavaScript modules.
 *
 * loading: Execute when document is still loading
 *
 * interactive: Execute when document has finished loading, but images,
 * stylesheets and frames are still loading.
 *
 * complete: Execute when document and all sub-resources have finished loading.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */
document.onreadystatechange = function () {
	if (document.readyState === "interactive") {
		init();
	}
};
