/**
 * Class
 * =============================================================================
 */
class Hamburger {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el, {
		openCb = () => null,
		closeCb = () => null,
	}) {
		this.el = el;

		this.openCb = openCb;
		this.closeCb = closeCb;

		this.init();
		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.isClosed = this.getIsClosedState();
	}

	initEvents() {
		this.el.addEventListener("click", (e) => this.handleClick(e));
	}

	// Helpers
	// -------------------------------------------------------------------------
	getIsClosedState() {
		return this.el.classList.contains("is-active");
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleClick(e = {}) {
		if (e) {
			e.preventDefault();
		}

		this.isClosed = !this.isClosed;

		if (this.isClosed) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		this.el.classList.add("is-active");

		this.isClosed = true;

		this.openCb();
	}

	close() {
		this.el.classList.remove("is-active");

		this.isClosed = false;

		this.closeCb();
	}

	disable() {
		this.el.setAttribute("aria-hidden", true);
		this.el.hidden = true;
	}

	enable() {
		this.el.setAttribute("aria-hidden", false);
		this.el.hidden = false;
	}

	// Life cycle
	// -------------------------------------------------------------------------
	destroy() {
		this.el.removeEventListener("click", (e) => this.handleClick(e));
	}
}

export default Hamburger;
