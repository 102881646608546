/**
 * Class
 * =============================================================================
 */
class Dropdown {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.init();
		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.collapse = this.el.querySelector(".c-header__collapse");
		this.mainLink = this.el.querySelector(".c-header__list-link");
		this.subListToggle = this.el.querySelector(".c-header__sub-toggle");
		this.subList = this.el.querySelector(".c-header__sub-list");
		this.subLinks = this.el.querySelectorAll(".c-header__sub-link");
	}

	initEvents() {
		this.el.addEventListener("click", () => this.handleClick());
		this.el.addEventListener("mouseenter", () => this.handleOpenSublist());
		this.el.addEventListener("mouseleave", () => this.handleCloseSublist());

		if (this.mainLink) {
			this.mainLink.addEventListener("focus", () => this.handleOpenSublist());
			this.mainLink.addEventListener("blur", () => this.handleCloseSublist());
		}

		if (this.subListToggle) {
			this.subListToggle.addEventListener("focus", () => this.handleOpenSublist());
			this.subListToggle.addEventListener("blur", () => this.handleCloseSublist());
		}

		if (this.subLinks) {
			this.subLinks.forEach((link) => {
				link.addEventListener("focus", () => this.handleOpenSublist());
				link.addEventListener("blur", () => this.handleCloseSublist());
			});
		}
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleClick() {
		this.el.setAttribute("aria-expanded", false);

		if (this.collapse && this.collapse.classList.contains("is-open")) {
			this.el.setAttribute("aria-expanded", true);
		}
	}

	handleOpenSublist() {
		this.el.setAttribute("aria-expanded", true);
	}

	handleCloseSublist() {
		this.el.setAttribute("aria-expanded", false);
	}

}

export default Dropdown;
