/**
 * Imports
 * =============================================================================
 */
import { getElBoundingBox } from "./../../utils/dom";

// =============================================================================
// Definition
// =============================================================================
class Container {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;
	}

	// Helpers
	// -------------------------------------------------------------------------
	getTop() {
		return getElBoundingBox(this.el, ["top"]);
	}

	getBottom() {
		return getElBoundingBox(this.el, ["bottom"]);
	}
}

export default Container;
