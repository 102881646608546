/**
 * Imports
 * =============================================================================
 */
import {
	Resize as ResizeManager,
	Scroll as ScrollManager,
} from "./../../managers";
import Canvas from "./Canvas";
import { isInView } from "./../../utils/dom";

/**
 * Class
 * =============================================================================
 */
class AnimatedGlobe {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.canvas = new Canvas(this.el.querySelector(".c-animated-globe__canvas"));
		this.offset = 200;

		this.isMobile = this.getMobileState();
		this.isInView = isInView(this.el, this.offset);
		this.isRenderedOnce = false;

		this.draw();
	}

	initBinds() {
		this.draw = this.draw.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	initEvents() {
		ResizeManager.add({ method: () => this.handleResize() });
		ScrollManager.add({ method: () => this.handleResize() });
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getMobileState() {
		return window.innerWidth < 500;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleResize() {
		this.isMobile = this.getMobileState();

		this.draw();
	}

	/* ====================================================================== *
	 * Render cycles
	 * ====================================================================== */
	draw() {
		this.isInView = isInView(this.el, this.offset);

		if (this.isMobile || !this.isInView) {
			this.canvas.cancel();
			this.isRenderedOnce = false;

			return;
		}

		if (this.isRenderedOnce) {
			return;
		}

		this.canvas.draw();
		this.isRenderedOnce = true;
	}
}

export default AnimatedGlobe;
