/**
 * Class
 * =============================================================================
 */
class Canvas {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	initBinds() {
		this.draw = this.draw.bind(this);
	}

	init() {
		this.animation = false;

		const size = 800;

		this.width = size;
		this.height = size;
		this.scene = new THREE.Scene();
		this.camera = new THREE.OrthographicCamera(this.width / -2, this.width / 2, this.height / 2, this.height / -2, 0.1, 10000);

		// render
		this.renderer = new THREE.WebGLRenderer({ canvas: this.el, antialias: true });
		this.renderer.setSize(size, size);

		// controls
		this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
		this.controls.dampingFactor = 0.1;
		this.controls.rotateSpeed = 0.05;
		this.controls.enableDamping = true;
		this.controls.enableZoom = false;
		this.controls.enablePan = false;

		this.camera.position.z = 500;
		this.controls.update();

		// scene
		this.scene.background = new THREE.Color(0x0A0D1F);

		// lights
		const light1 = new THREE.PointLight(0xffffff, 1, 2000);
		const light2 = new THREE.PointLight(0x63FFD9, 1, 2000);
		const light3 = new THREE.PointLight(0x63FFD9, 1, 4000);

		light1.position.set(400, 400, 0);
		light2.position.set(-400, 400, 0);
		light3.position.set(-200, -200, 200);
		this.scene.add(light1);
		this.scene.add(light2);
		this.scene.add(light3);

		// objects
		const dots = [];
		const dotGeometry = new THREE.SphereBufferGeometry(1, 4, 4);
		const dotTinyGeometry = new THREE.SphereBufferGeometry(0.5, 4, 4);
		const dotMediumGeometry = new THREE.SphereBufferGeometry(3, 12, 12);
		const dotMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
		const dotGreenMaterial = new THREE.MeshBasicMaterial({ color: 0x63FFD9 });

		// dotSpheres
		const dotSphereGeometry = new THREE.SphereBufferGeometry(1, 1, 1);
		const dotSphereMaterial = new THREE.MeshBasicMaterial({ color: 0x0A0D1F });

		this.dotSphere = new THREE.Mesh(dotSphereGeometry, dotSphereMaterial);
		this.dotSphere2 = new THREE.Mesh(dotSphereGeometry, dotSphereMaterial);
		this.dotSphere3 = new THREE.Mesh(dotSphereGeometry, dotSphereMaterial);
		this.dotSphere4 = new THREE.Mesh(dotSphereGeometry, dotSphereMaterial);

		this.dotSphere.position.set(0, 1, 0);
		this.dotSphere2.position.set(0, 1, 0);
		this.dotSphere3.position.set(0, 1, 0);
		this.dotSphere4.position.set(0, 1, 0);

		this.scene.add(this.dotSphere);
		this.scene.add(this.dotSphere2);
		this.scene.add(this.dotSphere3);
		this.scene.add(this.dotSphere4);

		this.createElements(150, dots, dotGeometry, dotMaterial, this.dotSphere, 60, false);
		this.createElements(50, dots, dotGeometry, dotMaterial, this.dotSphere2, 200, false);
		this.createElements(200, dots, dotTinyGeometry, dotMaterial, this.dotSphere3, 120, false);
		this.createElements(10, dots, dotMediumGeometry, dotGreenMaterial, this.dotSphere4, 220, true);

		// wireframeSphere
		const wfSphereGeometry = new THREE.SphereBufferGeometry(280, 5, 5);
		const wfSphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true, opacity: 0.03 });

		wfSphereMaterial.transparent = true;
		this.wfSphere = new THREE.Mesh(wfSphereGeometry, wfSphereMaterial);

		this.wfSphere.position.set(0, 1, 0);
		this.scene.add(this.wfSphere);

		const wfSphereGeometry2 = new THREE.SphereBufferGeometry(280, 6, 6);
		const wfSphereMaterial2 = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true, opacity: 0.05 });

		wfSphereMaterial2.transparent = true;

		this.wfSphere2 = new THREE.Mesh(wfSphereGeometry2, wfSphereMaterial2);

		this.wfSphere2.position.set(0, 1, 0);
		this.scene.add(this.wfSphere2);

		// transparantSphere
		const transSphereGeometry = new THREE.SphereBufferGeometry(160, 30, 30);
		const transSphereMaterial = new THREE.MeshPhongMaterial({
			color: 0xffffff,
			specular: 0x0A0D1F,
			shininess: 10,
			opacity: 0.1,
		});

		transSphereMaterial.transparent = true;

		const transSphere = new THREE.Mesh(transSphereGeometry, transSphereMaterial);

		transSphere.position.set(0, 1, 0);
		this.scene.add(transSphere);

		this.setImage();
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	createElements(number, dots, dotGeometry, dotMaterial, parent, radius, trace) {
		for (let i = 0; i < number; i++) {
			// Anchor point
			const anchor = new THREE.Object3D();

			parent.add(anchor);

			const dot = new THREE.Mesh(dotGeometry, dotMaterial);
			let x = 0;
			let y = 0;
			const z = 0;

			y = radius;
			x = radius;

			const scale = (Math.random() * 2) + 0.5;

			dot.scale.set(scale, scale, scale);

			anchor.rotation.z = this.degreesToRadians(Math.floor((Math.random() * 360) + 1));
			anchor.rotation.x = this.degreesToRadians(Math.floor((Math.random() * 360) + 1));
			anchor.rotation.y = this.degreesToRadians(Math.floor((Math.random() * 360) + 1));

			dot.position.set(x, y, z);

			if (trace) {
				const lineLength = 150;
				const lineGeometry = new THREE.Geometry();

				lineGeometry.vertices.push(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, -lineLength));
				const lineMaterial = new THREE.LineBasicMaterial({ color: 0x63FFD9, transparent: true });
				const line = new THREE.Line(lineGeometry, lineMaterial);

				line.position.set(110, 110, z);
				line.rotation.x = this.degreesToRadians(90);
				line.rotation.y = this.degreesToRadians(-45);

				this.animateLine({
					line: line,
					init: true,
				});
				anchor.add(line);
			}

			dots.push(dot);
			anchor.add(dot);
		}
	}

	degreesToRadians(degrees) {
		const pi = Math.PI;

		return degrees * (pi / 180);
	}

	animateLine({ line, init = false }) {
		line.material.opacity = 1;
		line.scale.z = 0.01;
		let delay = Math.random() * 2000;

		if (init) {
			delay *= 2;
		}

		const tweenGrow = new TWEEN.Tween(line.scale).to({
			z: 1,
		}, 1000).delay(delay);

		const tweenFadeOut = new TWEEN.Tween(line.material).to({
			opacity: 0,
		}, 1000).onComplete(() => {
			this.animateLine({ line: line });
		});

		tweenGrow.chain(tweenFadeOut);
		tweenGrow.start();
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	setImage() {
		this.dotSphere.rotation.z += 0.005;
		this.dotSphere.rotation.x -= 0.002;

		this.dotSphere4.rotation.y += 0.005;
		this.dotSphere4.rotation.x += 0.002;

		this.dotSphere3.rotation.y += 0.005;
		this.dotSphere3.rotation.x += 0.002;

		this.dotSphere2.rotation.x -= 0.005;
		this.dotSphere2.rotation.y -= 0.002;

		this.wfSphere.rotation.x -= 0.005;
		this.wfSphere.rotation.y -= 0.002;

		this.wfSphere2.rotation.y -= 0.005;
		this.wfSphere2.rotation.z -= 0.002;

		this.controls.enabled = true;

		TWEEN.update();
		this.controls.update();

		this.renderer.render(this.scene, this.camera);
	}

	draw() {
		this.animation = requestAnimationFrame(this.draw);

		this.setImage();
	}

	cancel() {
		this.controls.enabled = false;

		cancelAnimationFrame(this.animation);
	}
}

export default Canvas;
