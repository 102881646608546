// =============================================================================
// Definition
// =============================================================================
let instance = null;

class Scroll {

	// Constructor
	// -------------------------------------------------------------------------
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.initBinds();
		this.init();
		this.initEvents();

		return instance;
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.methods = [];
	}

	initEvents() {
		window.addEventListener("scroll", this.handleScroll);
	}

	initBinds() {
		this.handleScroll = this.handleScroll.bind(this);
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleScroll() {
		this.methods.forEach((method) => {
			const isFunction = typeof method === "function";

			if (method && isFunction) {
				method();
			}
		});
	}

	add({
		method = () => null,
	} = {}) {
		if (typeof method !== "function") {
			return;
		}

		this.methods.push(method);
	}

	// Life cycle
	// -------------------------------------------------------------------------
	destroy() {
		this.methods = [];

		window.removeEventListener("scroll", this.handleScroll);
	}
}

export default new Scroll();
