/**
 * Imports
 * =============================================================================
 */
import { Toggle } from "./../../extensions";

/**
 * Class
 * =============================================================================
 */
class Filter extends Toggle {
	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super({
			el: el,
			toggleClass: "is-open",
		});

		this.el = el;
		this.toggleTarget = this.el.querySelector(".c-filter__button");

		this.initBinds();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	initBinds() {
		this.toggle = this.toggle.bind(this);
	}

	initEvents() {
		this.toggleTarget.addEventListener("click", this.toggle);
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleToggle() {
		super.toggle();
	}
}

export default Filter;
