/**
 * Imports
 * =============================================================================
 */
import { Scroll as ScrollManager } from "./../../managers";
import ExplanationImage from "./ExplanationImage";
import ExplanationItem from "./ExplanationItem";
import Container from "./Container";
import { getElBoundingBox } from "./../../utils/dom";

/**
 * Class
 * =============================================================================
 */
class Explanation {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.imagesContainer = new Container(this.el.querySelector(".c-explanation__image-wrapper"));
		this.items = this.initItems();
		this.images = this.initImages();
	}

	initEvents() {
		ScrollManager.add({ method: () => this.handleScroll() });
	}

	initImages() {
		const els = this.el.querySelectorAll(".c-explanation__image");
		const result = [];

		for (let i = 0; i < els.length; i++) {
			const el = els[i];

			result.push(new ExplanationImage(el));
		}

		return result;
	}

	initItems() {
		const els = this.el.querySelectorAll(".c-explanation__item");
		const result = [];

		for (let i = 0; i < els.length; i++) {
			const el = els[i];

			result.push(new ExplanationItem(el));
		}

		return result;
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getNavHeight() {
		const nav = document.querySelector(".c-header");

		return getElBoundingBox(nav, ["height"]);
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleScroll() {
		this.updateItems();
	}

	updateItems() {
		const containerTop = this.imagesContainer.getTop();
		const containerBottom = this.imagesContainer.getBottom();

		for (let i = 0; i < this.items.length; i++) {
			const itemTop = this.items[i].getTop();
			const itemHeight = this.items[i].getHeight();
			const containerPositiveLimit = containerTop + (itemHeight / 2);
			const containerNegativeLimit = containerTop - (itemHeight / 2);

			this.images[i].hide();

			/**
			 * The default image has no highlighted parts. We only highlight
			 * a part of an image if the corresponding content item is
			 * approximately in the center of the viewport. This means if:
			 * (1) the container is within view for at least 50% of viewport
			 * height,
			 * (2) the corresponding content item is within a predefined zone
			 * in the container.
			 */
			if ((containerTop <= window.innerHeight / 2) && (containerBottom >= window.innerHeight / 2)) {
				if ((itemTop >= containerTop) && (itemTop < containerPositiveLimit)
					|| (itemTop < containerTop) && (itemTop >= containerNegativeLimit)) {
					this.images[i].show();
				}
			}
		}
	}
}

export default Explanation;
