/*eslint no-underscore-dangle: ["error", { "allow": ["_wq"] }]
*/

/**
 * Imports
 * =============================================================================
 */
import { Click, Toggle } from "./../../extensions";
import { Root, Scroll } from "./../../managers";

/**
 * Class
 * =============================================================================
 */
class Popup extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super({
			el: el,
			toggleClass: "is-open",
		});

		this.el = el;

		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.id = this.getId();
		this.bodyScrollPosition = "";
		this.buttons = this.initButtons();
		this.focusableContent = [...this.el.querySelectorAll("a, button, input, textarea, select, details,[tabindex]:not([tabindex='-1']")];
		this.trigger = false;

		this.disableContent();
	}

	initButtons() {
		const els = document.querySelectorAll(`[data-popup-trigger="${this.id}"]`);

		const result = [];

		for (let i = 0; i < els.length; i++) {
			const el = els[i];
			const type = el.getAttribute("data-popup-type");

			result.push(new Click(el, {
				clickCb: type === "open"
					? (e) => this.handleEnable(e)
					: () => this.handleDisable(),
			}));
		}

		return result;
	}

	initEvents() {
		Scroll.add({ method: () => this.getBodyScrollposition() });
		this.el.addEventListener("keydown", (e) => this.handleKeyDown(e));
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getId() {
		return this.el.getAttribute("id");
	}

	getBodyScrollposition() {
		this.bodyScrollPosition = window.scrollY;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleEnable(e) {
		super.enable();

		// Set the trigger to the DOM element that triggered the pop-up
		this.trigger = e.currentTarget;

		// Enable content and show in a11y tree
		this.enableContent();

		// Add focus to the first input field
		const firstInput = this.el.querySelector("input:not([type=hidden]");

		firstInput.focus();

		/**
		 * Disable body scroll and prevent the body from jumping to the top
		 * when activating the popup.
		 *
		 * More info:
		 * https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
		 */
		Root.handleDisableScroll(this.bodyScrollPosition);
	}

	handleDisable() {
		super.disable();

		//When popup is closed, pause the wistia video
		//otherwise it will keep playing

		window._wq = window._wq || [];

		window._wq.push({
			id: "_all",
			onReady: function(video) {
				video.pause();
			},
		});

		// Disable content and hide in a11y tree
		this.disableContent();

		// If the trigger is known, put the focus back on the trigger
		if (this.trigger) {
			this.trigger.focus();
		}

		Root.handleEnableScroll();
	}

	handleKeyDown(e) {
		if (e.key === "Escape") {
			this.handleDisable();
		}
	}

	disableContent() {
		this.el.setAttribute("aria-hidden", "true");
		this.focusableContent.forEach((el) => {
			el.setAttribute("tabIndex", "-1");
		});
	}

	enableContent() {
		this.el.setAttribute("aria-hidden", "false");

		this.focusableContent.forEach((el) => {
			el.setAttribute("tabIndex", "0");
		});

		const elementID = this.el.id;

		const videoWrappers = document.getElementsByClassName("video_popup_wrapper");

		for (let i = 0; i < videoWrappers.length; i++) {
			const dataTriggerID = videoWrappers[i].getElementsByClassName("c-pop-up__close")[0].getAttribute("data-popup-trigger");
			const playButton = videoWrappers[i].getElementsByClassName("w-big-play-button")[0];

			if (elementID === dataTriggerID) {
				playButton.style.display = "block";
			} else {
				playButton.style.display = "none";
			}
		}
	}
}

export default Popup;
