/**
 * Class
 * =============================================================================
 */
class Video {
	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;
		this.video = this.el.querySelector(".c-youtube-embed__player").id;
		this.image = this.el.querySelector(".c-youtube-embed__thumbnail");
		this.player = null;

		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		const url = new URL(this.video);
		const videoId = new URLSearchParams(url.search).get("v");

		YT.ready(() => {
			this.player = new YT.Player(this.video, {
				height: "390",
				width: "640",
				videoId,
			});
		});
	}

	initEvents() {
		this.image.addEventListener("click", () => this.handlePlayVideo());
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handlePlayVideo() {
		this.player.playVideo();

		this.image.classList.add("is-hidden");
	}
}

export default Video;
