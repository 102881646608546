// =============================================================================
// Definition
// =============================================================================
let instance = null;

class Root {

	// Constructor
	// -------------------------------------------------------------------------
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.init();

		return instance;
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.el = document.body;
	}

	// Helpers
	// -------------------------------------------------------------------------
	hasClass(name) {
		return this.el.classList.contains(name);
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleAddClass(name) {
		if (this.hasClass(name)) {
			return;
		}

		this.el.classList.add(name);
	}

	removeClass(name) {
		if (!this.hasClass(name)) {
			return;
		}

		this.el.classList.remove(name);
	}

	handleToggleClass(name) {
		const containsClass = this.hasClass(name);

		if (containsClass) {
			this.el.classList.remove(name);
		} else {
			this.el.classList.add(name);
		}
	}

	handleDisableScroll(scrollPosition) {
		const scrollY = scrollPosition;

		this.el.style.position = "fixed";
		this.el.style.width = "100%";
		this.el.style.top = `-${scrollY}px`;
	}

	handleEnableScroll() {
		const scrollY = document.body.style.top;

		this.el.style.position = "";
		this.el.style.top = "";

		window.scrollTo(0, parseInt(scrollY || "0") * -1);
	}
}

export default new Root;
