/**
 * Imports
 * =============================================================================
 */
import { getElBoundingBox } from "./../../utils/dom";
import { Toggle } from "./../../extensions";

// =============================================================================
// Definition
// =============================================================================
class ExplanationImage extends Toggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		super({
			el,
			toggleClass: "is-hidden",
		});

		this.el = el;
	}

	// Helpers
	// -------------------------------------------------------------------------
	getTop() {
		return getElBoundingBox(this.el, ["top"]);
	}

	getBottom() {
		return getElBoundingBox(this.el, ["bottom"]);
	}

	// Handlers
	// -------------------------------------------------------------------------
	show() {
		super.disable();
	}

	hide() {
		super.enable();
	}
}

export default ExplanationImage;
