/**
 * Imports
 * =============================================================================
 */
import { Click, Toggle } from "./../../extensions";
import { Resize } from "./../../managers";
import Content from "./Content";

/**
 * Class
 * =============================================================================
 */
class Collapse extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el, {
		index = 0,
		toggleCb = () => null,
	} = {}) {
		super({
			el,
			toggleClass: "is-open",
		});

		this.index = index;
		this.toggleCb = toggleCb;

		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.header = new Click(this.el.querySelector(".c-collapse__header"), {
			clickCb: () => this.handleClickHeader(),
		});

		this.content = new Content(this.el.querySelector(".c-collapse__content"));
	}

	initEvents() {
		Resize.add({ method: () => this.handleResize() });
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	enable() {
		this.content.open();

		super.enable();
	}

	disable() {
		this.content.close();

		super.disable();
	}

	handleClickHeader() {
		this.toggle();

		if (this.toggleCb) {
			this.toggleCb({
				index: this.index,
			});
		}
	}

	handleResize() {
		// Disable any transitions when resizing
		this.el.classList.add("is-resizing");

		// If the collapse is open, execute a content resize
		if (this.isToggled) {
			this.content.handleResize();
		}

		// Remove the transition immediatly after we've set update the content
		this.el.classList.remove("is-resizing");
	}

	/* ====================================================================== *
	 * Life cycle
	 * ====================================================================== */
	destroy() {
		Resize.destroy();
		this.header.destroy();
	}
}

export default Collapse;
