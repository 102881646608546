/**
 * Imports
 * =============================================================================
 */
import { Toggle } from "./../../extensions";
import { Root, Scroll, Resize } from "./../../managers";
import Hamburger from "./Hamburger";
import Dropdown from "./Dropdown";

/**
 * Class
 * =============================================================================
 */
class Header extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super({
			el: el,
			toggleClass: "is-open",
		});

		this.el = el;

		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.hamburger = new Hamburger(this.el.querySelector(".c-header__hamburger"), {
			openCb: () => this.handleHamburgerEnable(),
			closeCb: () => this.handleHamburgerDisable(),
		});

		this.nav = this.el.querySelector(".c-header__nav");

		this.dropdowns = this.initDropdowns();

		this.handleResize();
	}

	initEvents() {
		Scroll.add({ method: () => this.getBodyScrollposition() });
		Resize.add({ method: () => this.handleResize() });
	}

	initDropdowns() {
		const els = this.el.querySelectorAll(".c-header__list-item--has-sublist");

		return [...els].map((el) => {
			const item = new Dropdown(el);

			return item;
		});
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getBodyScrollposition() {
		this.bodyScrollPosition = window.scrollY;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleHamburgerEnable() {
		this.enable();

		this.nav.setAttribute("aria-expanded", true);

		/**
		 * Disable body scroll
		 *
		 * More info:
		 * https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
		 */
		if (window.innerWidth <= 992) {
			Root.handleDisableScroll(this.bodyScrollPosition);
		}
	}

	handleHamburgerDisable() {
		this.disable();
		this.nav.setAttribute("aria-expanded", false);

		Root.handleEnableScroll();
	}

	handleResize() {
		if (window.innerWidth <= 992) {
			this.hamburger.enable();
		} else {
			this.hamburger.disable();
		}
	}
}

export default Header;
